<template>
  <div>
    <!-- Coming soon page-->
    <div
      class="misc-wrapper"
    >
      <b-link
        href="https://fastinvoice.me"
        class="brand-logo d-flex align-items-center"
      >
        <span>
          <b-img
            :src="appLogoImage"
            alt="logo"
            style="width:80px;"
          />
        </span>
        <h2 class="brand-text text-primary ml-1">
          {{ appName }}
        </h2>
      </b-link>
      <b-overlay
        id="overlay-background"
        :show="pageIsLoading"
        variant="light"
        opacity="0.85"
        blur="5px"
        rounded="sm"
      >
        <template #overlay>
          <b-card

            class="text-center"
          >

            <h2>
              <b-spinner

                class="mr-1"
                label="Loading"
              />
              {{ overlayMsg }}
            </h2>

          </b-card>
        </template>
        <tax-editor
          v-show="selectedTaxObject"
          ref="taxEditorComponent"
          :tax-object="selectedTaxObject"
          :all-tax-list="locationAndCurrencyInfo.tax_data"
          @dataChanged="taxDataChanged"
        />
        <div
          class="misc-inner p-2 p-sm-3"
          style="max-width:900px;"
        >
          <form-wizard
            color="#7367F0"
            :title="null"
            :subtitle="null"
            shape="square"
            layout="vertical"
            finish-button-text="Finish"
            back-button-text="Previous"
            class="wizard-vertical mb-3"
            @on-complete="formSubmitted"
          >

            <!-- accoint details tab -->

            <tab-content
              title="Tax and Currency"
              :before-change="validationLocationInfo"
            >
              <validation-observer
                ref="locationInfoRules"
                tag="form"
              >
                <b-row>
                  <b-col
                    cols="12"
                    class="mb-2"
                  >
                    <h5 class="mb-0">
                      Select your country and default currency
                    </h5>
                    <small class="text-muted">
                      These will appear on invoices, estimates, and other places in the app. Make changes anytime by going to Tax and currency settings
                    </small>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                      v-if="countryOptions"
                      :label="$t('Country')"
                      label-for="country_KDR"
                    >
                      <v-select
                        v-model="locationAndCurrencyInfo.country_id"
                        class="lg-input"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="countryOptions"
                        :reduce="val => val.value"
                        autocomplete="chrome-off"
                        :clearable="false"
                        input-id="country_KDR"
                      />
                    </b-form-group>

                  </b-col>
                  <b-col md="12">
                    <b-form-group
                      v-if="countryStateOptions"
                      :label="$t('State')"
                      label-for="country_state_KDR"
                    >
                      <v-select
                        v-model="locationAndCurrencyInfo.country_state"
                        class="lg-input"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="countryStateOptions"
                        :reduce="val => val.value"
                        autocomplete="chrome-off"
                        :clearable="false"
                        input-id="country_state_KDR"
                      />
                    </b-form-group>

                  </b-col>
                  <b-col md="12">

                    <b-form-group
                      v-if="countryOptions"
                      :label="$t('Currency')"
                      label-for="currency_select"
                    >
                      <v-select

                        v-model="locationAndCurrencyInfo.currency_id"
                        class="lg-input"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="currencyOptions"
                        :reduce="val => val.value"
                        autocomplete="chrome-off"
                        :clearable="false"
                        input-id="currency_select"
                      />
                    </b-form-group>

                  </b-col>
                  <b-col
                    cols="12"
                    class="mb-2"
                  >
                    <h2 class="mt-1 mb-2">
                      Rates
                    </h2>
                    <span class="mb-2">A tax can have multiple rates associated with it. Edit a tax to add additional rates. Default taxes and rates will be applied to items.</span>
                    <hr>
                    <b-form-group

                      v-for="(data,index) in locationAndCurrencyInfo.tax_data"
                      :key="index"
                      :label="data.tax_name"
                    >
                      <div class="d-flex justify-content-between align-items-center">
                        <b-form-input
                          :value="data.tax_rates.filter(filter=>filter.is_default==1)[0].rate+'% '+ (data.tax_rates.length>1?'('+data.tax_rates.length+' more rates)':'')"
                          :readonly="true"
                          size="lg"
                        />
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="flat-primary"
                          class="ml-1"
                          @click="editTax(data)"
                        >
                          Edit
                        </b-button>
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="flat-danger"
                          class="ml-1"
                          @click="deleteTax(data)"
                        >
                          Delete
                        </b-button>
                      </div>
                    </b-form-group>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="flat-primary"
                      class="ml-2"
                      @click="addNewTax"
                    >
                      Add new tax
                    </b-button>
                  </b-col>
                </b-row>
              </validation-observer>
            </tab-content>
            <tab-content
              title="Business Details"
              :before-change="validationCompanyInfo"
            >
              <validation-observer
                ref="companyInfoRules"
                tag="form"
              >
                <b-row>
                  <b-col
                    cols="12"
                    class="mb-2"
                  >
                    <h5 class="mb-0">
                      Add your business details
                    </h5>
                    <small class="text-muted">
                      These will appear on invoices, estimates, and other places in the app. Make changes anytime by going to Company info in Settings
                    </small>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                      label="Registered business name"
                      label-for="business-name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="business-name"
                        rules="required"
                      >
                        <b-form-input
                          id="business-name"
                          v-model="companyInfoData.business_name"
                          size="lg"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-muted">Don’t have one? Just enter your own name here.</small>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label="Registered business address"
                      label-for="street-address"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Registered business address"
                        vid="Password"
                        rules="required"
                      >
                        <b-form-textarea
                          id="street-address"
                          v-model="companyInfoData.street_address"
                          size="lg"
                          rows="1"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small>Don’t have one? Type your home address.</small>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                      label="Business Email"
                      label-for="email"
                    > <validation-provider
                      #default="{ errors }"
                      name="Email"
                      vid="b-business-email"
                      rules="email|required"
                    >
                      <b-form-input
                        id="email"
                        v-model="companyInfoData.other_email"
                        size="lg"
                        placeholder="Enter your email..."
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <b-alert
                        variant="warning"
                        class="mt-1"
                        show
                      >  <div class="alert-body ">
                        <span>Note: This email is necesssarry to contact with your client when you send an email</span>
                      </div>

                      </b-alert>
                    </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="GST"
                      label-for="gst"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Gst"
                        vid="b-gst"
                      >
                        <b-form-input
                          id="gst"
                          v-model="companyInfoData.gst"
                          size="lg"
                          placeholder=""
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="WCB"
                      label-for="wbc"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="wcb"
                        vid="b-wbc"
                      >
                        <b-form-input
                          id="wbc"
                          v-model="companyInfoData.wbc"
                          size="lg"
                          placeholder=""
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                      label="Phone number"
                      label-for="phone-number"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Phone number"
                        rules=""
                      >
                        <b-input-group>
                          <b-input-group-prepend>
                            <v-select
                              v-model="companyInfoData.phone_code"
                              class="lg-input"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options="countryOptions"
                              :reduce="val => val.phone_code"
                              label="phone_text"
                              autocomplete="chrome-off"
                              :clearable="false"
                              input-id="phone_code"
                            />
                          </b-input-group-prepend>

                          <b-form-input
                            id="phone"
                            v-model="companyInfoData.phone"
                            v-numeric-only
                            type="number"
                            placeholder="Phone number"
                          >
                            size="lg"
                            :state="errors.length > 0 ? false:null"
                            />
                          </b-form-input></b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Website -->
                  <b-col cols="12">
                    <b-form-group
                      label="Business web site"
                      label-for="website"
                    >
                      <b-form-input
                        id="website"
                        v-model="companyInfoData.website"
                        size="lg"
                      />
                    </b-form-group>
                  </b-col>
                <!--/ Website -->
                </b-row>
              </validation-observer>
            </tab-content>

          </form-wizard>

        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import { $themeConfig } from '@themeConfig'
import Ripple from 'vue-ripple-directive'
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BImg,
  BRow,
  BCol,
  BFormGroup,
  BFormInput, BFormTextarea, BOverlay, BSpinner, BCard, BLink, BAlert,
  BFormInvalidFeedback, BInputGroup, BInputGroupPrepend, BButton,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import { required, email } from '@validations'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import axios from '@axios'
import accountStoreModule from '../../accountStoreModule'
// eslint-disable-next-line import/no-extraneous-dependencies

import taxEditor from '../../settings/components/TaxEditor.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BLink,
    BAlert,
    FormWizard,
    BOverlay,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BButton,
    taxEditor,
    TabContent,
    BRow,
    BSpinner,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      clevePhone: {
        phone: true,
        phoneRegionCode: 'US',
      },
      companyInfoData: {
        business_name: '',
        street_address: '',
        other_email: '',
        phone: '',
        website: '',
        phone_code: 0,
        gst: '',
        wbc: '',

      },
      locationAndCurrencyInfo: {
        currency_id: 0,
        country_id: 0,
        country_state: '',
        tax_start_month: 1,
        tax_start_day: 1,
        withholding_tax_enable: true,
        withholding_tax_label: 'Withholding tax',
        withholding_tax_rate: 0,
        tax_data: [],

      },
      selectedTaxObject: null,
      emptyTaxData: {
        id: '',
        tax_name: '',
        tax_rates: [{ rate: 0, is_default: 1 }],
        tax_type: 2,
        apply_to_all_items: 1,
        accumulative: [],

      },
      selectedGeoCountry: null,
      countryOptions: [],
      countryStateOptions: null,
      currencyOptions: [],
      required,
      email,
      pageIsLoading: true,
      overlayMsg: 'Loading...',

    }
  },
  watch: {
    'locationAndCurrencyInfo.country_id': function (val, oldVal) {
      if (oldVal !== 0) {
  this.locationAndCurrencyInfo.country_state = ''
      }

      this.handleCountrySelected(val)
    },
    'locationAndCurrencyInfo.country_state': function () {
      this.handleCountrySelected(this.locationAndCurrencyInfo.country_id)
    },
  },
  created() {
    this.fetchCurrencies()
    this.fetchCountries()
    setTimeout(() => this.fetchCurrentCountry(), 1000)
  },
  methods: {
    handleCountrySelected(val) {
      const filteredCountry = this.countryOptions.filter(f => f.value === val)

      if (filteredCountry.length > 0) {
        this.countryStateOptions = null

        if (filteredCountry[0].states) {
          const statesArr = []
          filteredCountry[0].states.forEach(sVal => {
            statesArr.push({ value: sVal.code, label: sVal.name })
          })
          this.countryStateOptions = statesArr
        }
        const countryVal = filteredCountry[0]
        this.putDefaultTaxes(countryVal)
        if (this.companyInfoData.phone_code === 0) {
          this.companyInfoData.phone_code = countryVal.phone_code
        }
      }
    },
    putDefaultTaxes(countryData) {
      this.locationAndCurrencyInfo.tax_data = []

      const newTaxData = JSON.parse(JSON.stringify(this.emptyTaxData))
      newTaxData.id = this.$helpers.generateUuid()
      if (countryData.tax_rates) {
        newTaxData.tax_name = countryData.tax_rates.type.toUpperCase()
        newTaxData.tax_rates = []
        if (countryData.tax_rates.states) {
          newTaxData.tax_rates.push({ rate: (countryData.tax_rates.rate * 100).toFixed(), is_default: 1 })
          if (this.locationAndCurrencyInfo.country_id !== 0) {
            Object.entries(countryData.tax_rates.states).forEach(entry => {
              // eslint-disable-next-line no-unused-vars
              const [key, value] = entry

              if (key === this.locationAndCurrencyInfo.country_state) {
                const regionTax = JSON.parse(JSON.stringify(this.emptyTaxData))
                regionTax.tax_name = value.type.toUpperCase()

                regionTax.tax_rates = []
                regionTax.tax_rates.push({ rate: (value.rate * 100).toFixed(), is_default: 1 })
                regionTax.id = this.$helpers.generateUuid()
                this.locationAndCurrencyInfo.tax_data.push(regionTax)
              }
            })
          }
        } else {
          newTaxData.tax_rates.push({ rate: (countryData.tax_rates.rate * 100).toFixed(), is_default: 1 })
        }
        this.locationAndCurrencyInfo.tax_data.push(newTaxData)
      }
    },
    taxDataChanged(taxData) {
      let currentIndex = -1
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.locationAndCurrencyInfo.tax_data.length; i++) {
        if (this.locationAndCurrencyInfo.tax_data[i].id === taxData.id) {
          currentIndex = i
        }
      }

      if (currentIndex >= 0) {
        this.$set(this.locationAndCurrencyInfo.tax_data, currentIndex, taxData)

        this.locationAndCurrencyInfo.tax_data[currentIndex] = taxData
        // console.log(this.locationAndCurrencyInfo.tax_data[currentIndex])
      } else {
        this.locationAndCurrencyInfo.tax_data.push(taxData)
      }
    },
    editTax(data) {
      this.selectedTaxObject = data
      setTimeout(() => this.$refs.taxEditorComponent.showEditor(), 100)
    },
    deleteTax(data) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.locationAndCurrencyInfo.tax_data.length; i++) {
        if (this.locationAndCurrencyInfo.tax_data[i].id === data.id) {
          this.locationAndCurrencyInfo.tax_data.splice(i, 1)
        } else {
          this.locationAndCurrencyInfo.tax_data[i].accumulative.forEach((val, index) => {
            if (val === data.id) {
              this.locationAndCurrencyInfo.tax_data[i].accumulative.splice(index, 1)
            }
          })
        }
      }
    },
    addNewTax() {
      this.selectedTaxObject = null
      setTimeout(() => this.$refs.taxEditorComponent.showEditor(), 100)
    },
    fetchMy() {
      store.dispatch('app/fetchMy')
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.pageIsLoading = false
          this.$router.push({ name: 'dashboard' })
        })
    },
    fetchCurrentCountry() {
      axios
        .get('https://api.ipregistry.co/?key=ajmi16mcc5d046s8')
        .then(response => {
          const currencyData = response.data.currency
          const countryData = response.data.location.country
          this.selectedGeoCountry = response.data.location
          this.currencyOptions.forEach(val => {
            // eslint-disable-next-line eqeqeq
            if (val.code == currencyData.code) {
              this.locationAndCurrencyInfo.currency_id = val.value
            }
          })
          this.countryOptions.forEach(val => {
            // eslint-disable-next-line eqeqeq
            if (val.code == countryData.code) {
              this.locationAndCurrencyInfo.country_id = val.value

             this.locationAndCurrencyInfo.country_state = this.selectedGeoCountry.region.code.replace(`${countryData.code}-`, '')
            }
          })
          this.pageIsLoading = false
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.pageIsLoading = false
        })
    },
    fetchCountries() {
      store.dispatch('app/fetchCountries', { with_taxes: true })
        .then(response => {
          const arr = []
          response.data.data.forEach(val => arr.push({
            label: val.name, value: val.id, code: val.code, phone_code: val.phone_code, phone_text: `${val.code} (+${val.phone_code})`, tax_rates: val.tax_rates, states: val.states,
          }))

          this.countryOptions = arr
        })
    },
    fetchCurrencies() {
      store.dispatch('app/fetchCurrencies', {})
        .then(response => {
          const arr = []
          response.data.data.forEach(val => arr.push({ label: `${val.currency_code} - ${val.currency_name}`, value: val.id, code: val.currency_code }))
          this.currencyOptions = arr
        })
    },
    formSubmitted() {
      this.pageIsLoading = true
      this.fetchMy()
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Your account is ready. Redirecting to app',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
    validationCompanyInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.companyInfoRules.validate().then(success => {
          if (success) {
            this.pageIsLoading = true
            store
              .dispatch('app-account/updateCompanyInfo', this.companyInfoData)
              .then(response => {
                if (this.companyInfoData.business_name !== '') {
                  this.$userHandler.setUsageStep('business_name_entered')
                }
                this.pageIsLoading = false
                this.userData = response.data.data
                this.isSuccess = true
                this.errorData = null
                resolve(true)
              })
              .catch(error => {
                resolve(true)
                if (error.response.status === 422) {
                  this.errorData = error.response.data
                }
              })
          } else {
            reject()
          }
        })
      })
    },
    validationLocationInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.locationInfoRules.validate().then(success => {
          if (success) {
            this.pageIsLoading = true
            store
              .dispatch('app-account/updateTaxAndCurrencyInfo', this.locationAndCurrencyInfo)
              .then(response => {
                this.pageIsLoading = false
                this.userData = response.data.data
                this.isSuccess = true
                this.errorData = null
                resolve(true)
              })
              .catch(error => {
                resolve(true)
                if (error.response.status === 422) {
                  this.errorData = error.response.data
                }
              })
          } else {
            reject()
          }
        })
      })
    },

  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-account'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, accountStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/pages/page-misc.scss';
.lg-input{
      height: 3.2857rem;
    font-size: 1.143rem;
    line-height: 1.25;
}
</style>
